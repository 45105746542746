import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import BlockContent from "@sanity/block-content-to-react";
import serializers from "../helper/TextSerializer";
import SEO from "../components/seo";
import PageHero from "../components/page-hero";
import Fader from "../helper/Fader";

export const query = graphql`
  {
    allSanityFirm {
      edges {
        node {
          pageSEO {
            metaDescription
            metaTitle
            metaImage {
              asset {
                url
              }
            }
          }
          backgroundImage {
            asset {
              fixed(width: 1600, height: 900) {
                ...GatsbySanityImageFixed_withWebp_noBase64
              }
            }
          }
          pageHeading
          _rawContent(resolveReferences: { maxDepth: 8 })
        }
      }
    }
  }
`;

class OurFirm extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.state = { toggle: false };
  }

  handleClick(e) {
    e.preventDefault();
    this.setState(state => ({
      toggle: !state.toggle,
    }));
  }

  render() {
    const firm = this.props.data.allSanityFirm.edges[0].node;

    return (
      <Layout
        className="page-firm-container"
        backgroundImage={firm.backgroundImage?.asset?.fixed}
      >
        <SEO
          title={(firm.pageSEO && firm.pageSEO.metaTitle) || firm.pageHeading}
          description={firm.pageSEO && firm.pageSEO.metaDescription}
          image={firm.pageSEO && firm.pageSEO.metaImage}
        />
        <PageHero title={firm.pageHeading} />
        <Fader>
          <div className="firm-main page-body">
            <div className="page-body-inner">
              <section className="firm-content">
                <BlockContent
                  className="firm-content-inner"
                  blocks={firm._rawContent.firmContent}
                  serializers={serializers}
                  renderContainerOnSingleChild={true}
                />
              </section>
            </div>
          </div>
        </Fader>
      </Layout>
    );
  }
}

export default OurFirm;
